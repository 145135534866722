import type { RenderManualFieldExtensionConfigScreenCtx } from 'datocms-plugin-sdk';
import { Canvas, Form, SelectField } from 'datocms-react-ui';
import { useCallback, useState } from 'react';

import type { ValidManualExtensionParameters } from '../types';

type Props = {
  ctx: RenderManualFieldExtensionConfigScreenCtx;
};

export default function ConfigScreen({ ctx }: Props) {
  const [formValues, setFormValues] = useState<Partial<ValidManualExtensionParameters>>(
    ctx.parameters || {},
  );

  const update = useCallback(
    (field: string, value: unknown) => {
      const newParameters = { ...formValues, [field]: value };
      setFormValues(newParameters);
      ctx.setParameters(newParameters);
    },
    [ctx, formValues],
  );

  const options = Object.values(ctx.fields)
    .filter(
      (field) =>
        field?.relationships.item_type.data.id === ctx.itemType.id &&
        field.id !== ctx.pendingField.id,
    )
    .map((field) => ({
      label: field?.attributes.label,
      value: field?.attributes.api_key,
    }));

  return (
    <Canvas ctx={ctx}>
      <Form>
        <SelectField
          id='headlineFieldApiKey'
          name='headlineFieldApiKey'
          label='Select the field that the scraped headline will write to'
          selectInputProps={{ isMulti: false, options }}
          value={options.find((o) => o.value === formValues.headlineFieldApiKey) || []}
          onChange={(selectedOption) => {
            update('headlineFieldApiKey', selectedOption?.value);
          }}
          required
        />
        <SelectField
          id='imageUrlFieldApiKey'
          name='imageUrlFieldApiKey'
          label='Select the field that the scraped image will write to'
          selectInputProps={{ isMulti: false, options }}
          value={options.find((o) => o.value === formValues.imageUrlFieldApiKey) || []}
          onChange={(selectedOption) => {
            update('imageUrlFieldApiKey', selectedOption?.value);
          }}
          required
        />
        <SelectField
          id='sourceFieldApiKey'
          name='sourceFieldApiKey'
          label='Select the field that the scraped source will write to'
          selectInputProps={{ isMulti: false, options }}
          value={options.find((o) => o.value === formValues.sourceFieldApiKey) || []}
          onChange={(selectedOption) => {
            update('sourceFieldApiKey', selectedOption?.value);
          }}
          required
        />
        <SelectField
          id='sourceIconUrlFieldApiKey'
          name='sourceIconUrlFieldApiKey'
          label='Select the field that the scraped source icon will write to'
          selectInputProps={{ isMulti: false, options }}
          value={options.find((o) => o.value === formValues.sourceIconUrlFieldApiKey) || []}
          onChange={(selectedOption) => {
            update('sourceIconUrlFieldApiKey', selectedOption?.value);
          }}
          required
        />
      </Form>
    </Canvas>
  );
}

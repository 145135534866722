import type { RenderFieldExtensionCtx } from 'datocms-plugin-sdk';
import { Button, Canvas } from 'datocms-react-ui';
import { useCallback, useEffect, useState } from 'react';

import type { ScrapedPost, ValidManualExtensionParameters } from '../types';

import './styles.module.css';

type PropTypes = {
  ctx: RenderFieldExtensionCtx;
};

function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}

export default function FieldExtension({ ctx }: PropTypes) {
  const params = ctx.parameters as ValidManualExtensionParameters;

  const fieldApiKey = ctx.field.attributes.api_key;
  const headlineFieldApiKey = params.headlineFieldApiKey;
  const imageUrlFieldApiKey = params.imageUrlFieldApiKey;
  const sourceFieldApiKey = params.sourceFieldApiKey;
  const sourceIconUrlFieldApiKey = params.sourceIconUrlFieldApiKey;

  const url = ctx.formValues[fieldApiKey] as string;
  const headline = ctx.formValues[headlineFieldApiKey] as string;
  const imageUrl = ctx.formValues[imageUrlFieldApiKey] as string;
  const source = ctx.formValues[sourceFieldApiKey] as string;
  const sourceIconUrl = ctx.formValues[sourceIconUrlFieldApiKey] as string;

  const [loading, setLoading] = useState(false);
  const [showScrapedFields, setShowScrapedFields] = useState(false);

  const handleScrapeClick = useCallback(() => {
    setLoading(true);

    fetch(
      `${process.env.REACT_APP_SCRAPE_ENDPOINT_URL}?token=${process.env.REACT_APP_SECRET_API_TOKEN}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ url: url }),
      },
    )
      .then((res) => res.json())
      .then(({ data }: { data: ScrapedPost }) => {
        ctx.setFieldValue(headlineFieldApiKey, data.title);
        ctx.setFieldValue(imageUrlFieldApiKey, data.image);
        ctx.setFieldValue(sourceFieldApiKey, data.source);
        ctx.setFieldValue(sourceIconUrlFieldApiKey, data.sourceIcon);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    ctx,
    headlineFieldApiKey,
    imageUrlFieldApiKey,
    sourceFieldApiKey,
    sourceIconUrlFieldApiKey,
    url,
  ]);

  const handleToggleFieldsClick = useCallback(() => {
    setShowScrapedFields((prev) => !prev);
  }, []);

  useEffect(() => {
    ctx.toggleField(headlineFieldApiKey, showScrapedFields);
    ctx.toggleField(imageUrlFieldApiKey, showScrapedFields);
    ctx.toggleField(sourceFieldApiKey, showScrapedFields);
    ctx.toggleField(sourceIconUrlFieldApiKey, showScrapedFields);
  }, [
    ctx,
    headlineFieldApiKey,
    imageUrlFieldApiKey,
    sourceFieldApiKey,
    sourceIconUrlFieldApiKey,
    showScrapedFields,
  ]);

  return (
    <Canvas ctx={ctx}>
      <Button buttonType='muted' onClick={handleScrapeClick} disabled={loading || !isValidUrl(url)}>
        🪄 Scrape Article
      </Button>

      <a
        href={url}
        target='_blank'
        rel='noopener noreferrer'
        style={{ color: 'var(--base-body-color)', textDecoration: 'none' }}
      >
        <article
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 'var(--spacing-l)',
            marginTop: 'var(--spacing-l)',
            marginBottom: 'var(--spacing-l)',
          }}
        >
          <img
            src={imageUrl}
            alt={headline}
            style={{
              width: 100,
              height: 100,
              objectFit: 'cover',
              backgroundColor: 'var(--lighter-bg-color)',
            }}
          />
          <div style={{ flexGrow: 1 }}>
            <h2
              style={{
                fontSize: 'var(--font-size-xl)',
                marginTop: 0,
                marginBottom: 8,
                lineHeight: 1.25,
              }}
            >
              {headline}
            </h2>
            <footer style={{ display: 'flex', gap: 'var(--spacing-m)' }}>
              <img
                src={sourceIconUrl}
                alt={source}
                style={{
                  width: 20,
                  height: 20,
                  objectFit: 'cover',
                  backgroundColor: 'var(--lighter-bg-color)',
                }}
              />
              {source}
            </footer>
          </div>
        </article>
      </a>

      <Button
        buttonSize='xxs'
        buttonType='muted'
        onClick={handleToggleFieldsClick}
        disabled={loading || !isValidUrl(url)}
      >
        {showScrapedFields ? 'Hide Scraped Fields' : 'Edit Scraped Fields'}
      </Button>
    </Canvas>
  );
}

import 'datocms-react-ui/styles.css';

import {
  type IntentCtx,
  type RenderFieldExtensionCtx,
  type RenderManualFieldExtensionConfigScreenCtx,
  connect,
} from 'datocms-plugin-sdk';
import ConfigScreen from './entrypoints/ConfigScreen';
import FieldExtension from './entrypoints/FieldExtension';
import { render } from './utils/render';

const FIELD_EXTENSION_ID = 'left_right_scraper';

connect({
  renderManualFieldExtensionConfigScreen(
    fieldExtensionId: string,
    ctx: RenderManualFieldExtensionConfigScreenCtx,
  ) {
    if (fieldExtensionId === FIELD_EXTENSION_ID) {
      render(<ConfigScreen ctx={ctx} />);
    }
  },
  manualFieldExtensions(_: IntentCtx) {
    return [
      {
        id: 'left_right_scraper',
        name: 'LeftRight Custom Scraper',
        type: 'addon',
        fieldTypes: ['string'],
        configurable: true,
      },
    ];
  },
  renderFieldExtension(fieldExtensionId: string, ctx: RenderFieldExtensionCtx) {
    switch (fieldExtensionId) {
      case 'left_right_scraper':
        return render(<FieldExtension ctx={ctx} />);
    }
  },
});
